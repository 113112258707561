import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import React from 'react';
import { useRouter } from 'next/router';
import { MAIN_BREADCRUMB } from '../../services/seo/breadcrumbs/consts';
import texts from './texts.json';

export const HomepageBreadcrumbs = () => {
  const { pathname } = useRouter();
  const breadcrumbs = [
    MAIN_BREADCRUMB,
    {
      title: texts.yad1,
      href: pathname,
    },
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
