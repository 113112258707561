import React, { ChangeEvent } from 'react';
import texts from './subcategory-radio-selector.texts.json';
import styles from './subcategory-radio-selector.module.scss';
import { RadioSelector } from '../../components/radio-selector/radio-selector';
import {
  getSubcategoryHebById,
  SUBCATEGORIES_HEB,
  SUBCATEGORIES_ID,
  type SubcategoriesIds,
} from '../../services/categories/categories.utils';

type Event = { target: { value: string } };

export type Subcategory = {
  id: SubcategoriesIds | string;
  name: string;
};

type Props = {
  name: string;
  onChange: (subcategory: Subcategory) => void;
};

export const SubcategoryRadioSelector = ({ onChange, name }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLFieldSetElement>) => {
    const { target } = event as unknown as Event;
    const value = target.value as SubcategoriesIds;
    onChange({
      id: value,
      name: getSubcategoryHebById(value),
    });
  };

  return (
    <fieldset
      className={styles.subcategoryRadioSelectorBox}
      onChange={handleChange}
    >
      <RadioSelector name={name} value="" label={texts.all} isChecked />
      <RadioSelector
        name={name}
        value={SUBCATEGORIES_ID.FOR_SALE}
        label={SUBCATEGORIES_HEB.FOR_SALE}
      />
      <RadioSelector
        name={name}
        value={SUBCATEGORIES_ID.RENT}
        label={SUBCATEGORIES_HEB.RENT}
      />
      <RadioSelector
        name={name}
        value={SUBCATEGORIES_ID.COMMERCIAL}
        label={SUBCATEGORIES_HEB.COMMERCIAL}
      />
    </fieldset>
  );
};
