import type { Yad1Developer } from '@y2/api-clients/yad1';
import styles from './developer-item.module.scss';
import { LambdaImage } from '@y2/image-resize';
import env from '@y2/environments';
import Link from 'next/link';
import React from 'react';
import { IMAGE_FALLBACK } from '../../../services/assets';

type Props = {
  developer: Yad1Developer;
};

export const DeveloperItem = ({ developer }: Props) => {
  const { name, id, logo } = developer;

  return (
    <li className={styles.developerItem}>
      <Link href={`${env.routes.mainSite}/yad1/developers/${id}`}>
        <LambdaImage
          className={styles.image}
          fill
          alt={name}
          src={logo}
          fallbackSrc={IMAGE_FALLBACK}
        />
      </Link>
    </li>
  );
};
