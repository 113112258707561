/* eslint-disable max-lines */
import { BASE_PATH } from '../constants';
import altTexts from './alt-texts.json';
import Image, { ImageProps } from 'next/image';
import env from '@y2/environments';

type Props = Omit<ImageProps, 'src' | 'alt'>;

const PATH = `${BASE_PATH}/minispot/`;

const DEFAULT_SIZE = {
  width: 52,
  height: 52,
};

const ImageComponent = (props: ImageProps) => <Image {...props} />;

export const SecondHandMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}2nd_hand.svg`}
    alt={altTexts.secondHand}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AnnouncementMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Announcement.svg`}
    alt={altTexts.Announcement}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const TradeInMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Trade-in.svg`}
    alt={altTexts['Trade-in']}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DrushimMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}drushim.svg`}
    alt={altTexts.drushim}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UploadMediaMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}media_uploads.svg`}
    alt={altTexts.media_uploads}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UploadVideoMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}uploads.svg`}
    alt={altTexts.video_uploads}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const RealEstateMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}realestate.svg`}
    alt={altTexts.realestate}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const Upgrade1MiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Upgrade1.svg`}
    alt={altTexts.Upgrade1}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const Drushim1MiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}drushim1.svg`}
    alt={altTexts.drushim1}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SafetyMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}safety.svg`}
    alt={altTexts.safety}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const AttractivenessMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Attractiveness.svg`}
    alt={altTexts.Attractiveness}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const VirtualNumberMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Virtual_number.svg`}
    alt={altTexts.Virtual_number}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const EditMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}edit.svg`}
    alt={altTexts.edit}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ShieldMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}shield.svg`}
    alt={altTexts.shield}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CallsMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Calls.svg`}
    alt={altTexts.Calls}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const YzerMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Yzer.svg`}
    alt={altTexts.Yzer}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const GuideMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}guide.svg`}
    alt={altTexts.guide}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SlidesMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}slides.svg`}
    alt={altTexts.slides}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DashbaordMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Dashbaord.svg`}
    alt={altTexts.Dashbaord}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BounceMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}bounce.svg`}
    alt={altTexts.bounce}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const RentPlusLogoIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}rentPlusLogo.svg`}
    alt={altTexts.rentPlusLogo}
    {...DEFAULT_SIZE}
    {...args}
  />
);
export const LegalMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}legal.svg`}
    alt={altTexts.legal}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const StopwatchMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}stopwatch.svg`}
    alt={altTexts.stopwatch}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DooronMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Dooron.svg`}
    alt={altTexts.Dooron}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BusinessMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}business.svg`}
    alt={altTexts.business}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LockMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}lock.svg`}
    alt={altTexts.lock}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UnlockMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}unlock.svg`}
    alt={altTexts.unlock}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NotificationMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Notification.svg`}
    alt={altTexts.Notification}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CalculatorMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}calculator.svg`}
    alt={altTexts.calculator}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PetsMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}pets.svg`}
    alt={altTexts.pets}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UpgradeMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}upgrade.svg`}
    alt={altTexts.upgrade}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const PricelistMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Pricelist.svg`}
    alt={altTexts.Pricelist}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DataMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}data.svg`}
    alt={altTexts.data}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ProfessionalMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}professional.svg`}
    alt={altTexts.professional}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UserMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}user.svg`}
    alt={altTexts.user}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UpgradeStripMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}upgrade_strip.svg`}
    alt={altTexts.upgradeStrip}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LaptopMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Laptop.svg`}
    alt={altTexts.user}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LocationMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}location.svg`}
    alt={altTexts.user}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const ApplePayMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}applepay.svg`}
    alt={altTexts.applePay}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const GooglePayMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}googlepay.svg`}
    alt={altTexts.googlePay}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CreditCardMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}creditcard.svg`}
    alt={altTexts.creditCard}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const TicketsMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}tickets.svg`}
    alt={altTexts.tickets}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const KeysMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Keys.svg`}
    alt={altTexts.keys}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const KeysActiveMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}Keys_Active.svg`}
    alt={altTexts.keysActive}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const GiftMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}gift.svg`}
    alt={altTexts.gift}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const NewsLetterSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}newsletter.svg`}
    alt={altTexts.newsLetter}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BossCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}boss.svg`}
    alt={altTexts.bossCarFamily}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const BossColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}boss-colorful.svg`}
    alt={altTexts.bossCarFamily}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DefaultCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}boss.svg`}
    alt={altTexts.defaultCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DefaultColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}boss-colorful.svg`}
    alt={altTexts.defaultCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CommercialCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}commercial.svg`}
    alt={altTexts.commercialCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CommercialColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}commercial-colorful.svg`}
    alt={altTexts.commercialCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CrossoverCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}crossover.svg`}
    alt={altTexts.crossoverCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CrossoverColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}crossover-colorful.svg`}
    alt={altTexts.crossoverCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FamilyCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}family.svg`}
    alt={altTexts.familyCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const FamilyColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}family-colorful.svg`}
    alt={altTexts.familyCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const JeepCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}jeep.svg`}
    alt={altTexts.jeepCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const JeepColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}jeep-colorful.svg`}
    alt={altTexts.jeepCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LuxuryCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}luxury.svg`}
    alt={altTexts.luxuryCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const LuxuryColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}luxury-colorful.svg`}
    alt={altTexts.luxuryCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MiniCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}mini.svg`}
    alt={altTexts.miniCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MiniColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}mini-colorful.svg`}
    alt={altTexts.miniCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MinivanCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}minivan.svg`}
    alt={altTexts.minivanCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MinivanColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}minivan-colorful.svg`}
    alt={altTexts.minivanCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SportCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}sport.svg`}
    alt={altTexts.sportCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SportColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}sport-colorful.svg`}
    alt={altTexts.sportCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const TenderCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}tender.svg`}
    alt={altTexts.tenderCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const TenderColorfulCarType = (args: Props) => (
  <ImageComponent
    src={`${PATH}tender-colorful.svg`}
    alt={altTexts.tenderCarType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const UploadImagePlaceholder = (args: Props) => (
  <ImageComponent
    src={`${PATH}upload-image-placeholder.svg`}
    alt={altTexts.uploadImagePlaceholder}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MortgageMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}mortgage.svg`}
    alt={altTexts.mortgage}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CarMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}newCar.svg`}
    alt={altTexts.newCar}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const GardenMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}garden.svg`}
    alt={altTexts.garden}
    width={40}
    height={40}
    {...args}
  />
);

export const OfficeMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}office.svg`}
    alt={altTexts.office}
    width={40}
    height={40}
    {...args}
  />
);

export const InvestMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}invest.svg`}
    alt={altTexts.invest}
    width={40}
    height={40}
    {...args}
  />
);

export const SeaMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}sea.svg`}
    alt={altTexts.sea}
    width={40}
    height={40}
    {...args}
  />
);

export const DuplexMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}duplex.svg`}
    alt={altTexts.duplex}
    width={40}
    height={40}
    {...args}
  />
);

export const NewProjectMiniSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}new.svg`}
    alt={altTexts.newProject}
    width={40}
    height={40}
    {...args}
  />
);

export const CreditCardSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}creditCard.svg`}
    alt={altTexts.creditCard}
    width={40}
    height={40}
    {...args}
  />
);

export const PickupSpotIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}pickup.svg`}
    alt={altTexts.pickup}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifyLocationIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-location.svg`}
    alt={altTexts.verifyLocation}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifySecureIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-secure.svg`}
    alt={altTexts.verifySecure}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifyCheckedIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-checked.svg`}
    alt={altTexts.verifyChecked}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifyTimeIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-time.svg`}
    alt={altTexts.verifyTime}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifyLocationWhiteBgIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-location-white-bg.svg`}
    alt={altTexts.verifyLocation}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifySecureWhiteBgIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-secure-white-bg.svg`}
    alt={altTexts.verifySecure}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifyCheckedWhiteBgIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-checked-white-bg.svg`}
    alt={altTexts.verifyChecked}
    width={40}
    height={40}
    {...args}
  />
);

export const VerifyTimeWhiteBgIllustration = (args: Props) => (
  <ImageComponent
    src={`${PATH}verify-time-white-bg.svg`}
    alt={altTexts.verifyTime}
    width={40}
    height={40}
    {...args}
  />
);

export const DualUseMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-dual-use-black-white.svg`}
    alt={altTexts.DualUseMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DualUseColorfulMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-dual-use-colorful.svg`}
    alt={altTexts.DualUseMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MotocrossMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-motocross-black-white.svg`}
    alt={altTexts.motocrossMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const MotocrossColorfulMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-motocross-colorful.svg`}
    alt={altTexts.motocrossMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SportsRoadMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-sports-road-black-white.svg`}
    alt={altTexts.roadSportsMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const SportsRoadColorfulMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-sports-road-colorful.svg`}
    alt={altTexts.roadSportsMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CustomMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-custom-black-white.svg`}
    alt={altTexts.customMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const CustomColorfulMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-custom-colorful.svg`}
    alt={altTexts.customMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DefaultMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-dual-use-black-white.svg`}
    alt={altTexts.defaultMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);

export const DefaultColorfulMotorcycleType = (args: Props) => (
  <ImageComponent
    src={`${env.assets.path}/vehicles/motorcycle-dual-use-colorful.svg`}
    alt={altTexts.defaultMotorcycleType}
    {...DEFAULT_SIZE}
    {...args}
  />
);
