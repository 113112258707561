/* eslint-disable custom-rules/use-state-setters-are-private */
import { FormEventHandler, useEffect, useState } from 'react';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import shuffle from 'lodash/shuffle';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import type {
  GetProjectsSchema,
  Yad1DevelopersResponse,
} from '@y2/api-clients/yad1';
import { useUserDetails } from '@y2/auth-common/user-details-query';
import env from '@y2/environments';
import { log } from '@y2/log';

import texts from './index.text.json';
import { Cover } from '../components/cover/cover';
import { CoverForm } from '../components/cover-form/cover-form';
import { GeneralLayout } from '../components/general-layout/general-layout';
import { MetaTags } from '../components/meta-tags/meta-tags';
import { Wrapper } from '../components/wrapper/wrapper';
import {
  pushCategoryClickEvent,
  pushSearchEvent,
} from '../features/analytics/feed-events';
import { pushPageEvent } from '../features/analytics/home-page-events';
import {
  type AddressMaster,
  CoverAddressAutocomplete,
} from '../features/cover-address-autocomplete/cover-address-autocomplete';
import { Developers } from '../features/developers/developers';
import {
  developeresPromotionsMock,
  type DeveloperPromotion,
} from '../features/developers-promotions/consts';
import { DeveloperPromotions } from '../features/developers-promotions/developers-promotions';
import { HomepageBreadcrumbs } from '../features/homepage-breadcrumbs/homepage-breadcrumbs';
import { NewProperties } from '../features/new-properties/new-properties';
import { PageAboutText } from '../features/page-about-text/page-about-text';
import { PageProjects } from '../features/page-projects/page-projects';
import {
  Subcategory,
  SubcategoryRadioSelector,
} from '../features/subcategory-radio-selector/subcategory-radio-selector';
import { getYad1DevelopersOptions } from '../query-hooks/yad1-developers';
import { getYad1ProjectsOptions } from '../query-hooks/yad1-projects';
import { assetsBasePath } from '../services/assets';
import { ALL_SUB_CATEGORY_HEB } from '../services/categories/categories.utils';
import { CLIENT_QUERY_KEYS } from '../services/params/consts';

const MAX_YAD1_DEVELOPERS = 30;

type Props = {
  developersRes: Yad1DevelopersResponse;
  projectsRes: GetProjectsSchema;
};

const PROJECTS_PER_PAGE = 9;

const IndexPage = ({ developersRes, projectsRes }: Props) => {
  const router = useRouter();
  const { data: user, isLoading } = useUserDetails();
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [currentSubcategory, setCurrentSubcategory] = useState<Subcategory>({
    id: '',
    name: ALL_SUB_CATEGORY_HEB,
  });
  const [currentAddress, setCurrentAddress] = useState<
    AddressMaster | null | undefined
  >();
  const [developerPromotions, setDeveloperPromotions] = useState<
    DeveloperPromotion[]
  >([]);
  useEffect(() => {
    setDeveloperPromotions(shuffle(developeresPromotionsMock));
  }, []);

  useEffect(() => {
    if (isLoading) return;
    pushPageEvent(user?.id);
  }, [isLoading]);

  const handleSubcategoryChange = (subCategory: Subcategory) => {
    setCurrentSubcategory(subCategory);
    pushCategoryClickEvent(subCategory.name);
  };

  const pushQueryParams = () => {
    router.push({
      pathname: 'newprojects',
      query: {
        ...(currentSubcategory.id && {
          [CLIENT_QUERY_KEYS.DEAL_TYPE]: currentSubcategory.id,
        }),
        ...(currentAddress?.top_area_id && {
          [CLIENT_QUERY_KEYS.TOP_AREA]: currentAddress.top_area_id,
        }),
        ...(currentAddress?.area_id && {
          [CLIENT_QUERY_KEYS.AREA]: currentAddress.area_id,
        }),
        ...(currentAddress &&
          'city_id' in currentAddress && {
            [CLIENT_QUERY_KEYS.CITY]: currentAddress.city_id,
          }),
        ...(currentAddress &&
          'hood_id' in currentAddress && {
            [CLIENT_QUERY_KEYS.HOOD]: currentAddress.hood_id,
          }),
      },
    });
  };

  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (!isAddressValid) return;
    pushSearchEvent(currentSubcategory.name, currentAddress?.full_title_text);
    pushQueryParams();
  };

  useEffect(() => {
    if (!currentAddress || !isAddressValid) return;
    pushSearchEvent(currentSubcategory.name, currentAddress?.full_title_text);
    pushQueryParams();
  }, [isAddressValid, currentAddress]);

  return (
    <>
      <MetaTags
        metaTitle={texts.meteTitle}
        metaDescription={texts.metaDescription}
        canonicalUrl={`${env.routes.mainSite}${router.basePath}`}
        robotContent="follow,index"
      />
      <GeneralLayout>
        <div>
          <Cover
            bgVideo={`${assetsBasePath}hp-cover-compressed.mp4`}
            pageHeading={texts.heading}
          >
            <CoverForm onSubmit={handleSearchSubmit}>
              <SubcategoryRadioSelector
                name={CLIENT_QUERY_KEYS.DEAL_TYPE}
                onChange={handleSubcategoryChange}
              />
              <CoverAddressAutocomplete
                onChange={setCurrentAddress}
                mobileHeading={currentSubcategory.name}
                setIsValid={setIsAddressValid}
                isValid={isAddressValid}
              />
            </CoverForm>
          </Cover>
          <PageProjects
            projects={projectsRes.projects}
            total={projectsRes.total}
            heading={texts.projectsAcrossTheCountry}
            placement="main_feed"
          />
          <DeveloperPromotions developerPromotions={developerPromotions} />
          <NewProperties />
          {developersRes?.developers.length && (
            <Developers developers={developersRes.developers} />
          )}
          <Wrapper>
            <PageAboutText aboutText={texts.aboutText} />
            <HomepageBreadcrumbs />
          </Wrapper>
        </div>
      </GeneralLayout>
    </>
  );
};
export const getServerSideProps: GetServerSideProps = async ({
  query,
  req,
}) => {
  try {
    const { page } = query;
    const queryClient = new QueryClient();

    const fetchDevelopers = () => {
      return queryClient.fetchQuery(
        getYad1DevelopersOptions({ limit: MAX_YAD1_DEVELOPERS }),
      );
    };

    const [developersRes, projectsRes] = await Promise.all([
      fetchDevelopers(),
      queryClient.fetchQuery(
        getYad1ProjectsOptions(
          {
            page: Number(page) || 1,
            limit: PROJECTS_PER_PAGE,
          },
          {},
          req.headers,
        ),
      ),
    ]);

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
        developersRes,
        projectsRes,
      } as Props,
    };
  } catch (error) {
    log.error(error, 'failed to execute Page');

    throw error;
  }
};

export default IndexPage;
