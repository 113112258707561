import { cropStyleMap } from '../../crop-styles';

export const LAMBDA_MINIMIZED_IMAGE_SIZE = {
  width: 10,
  height: 14,
};

export const DEFAULT_CROP_STYLE = cropStyleMap.scaleWidth;

export const ORIGINAL_IMAGE_CROP = cropStyleMap.none;
