import Link from 'next/link';
import type { DeveloperPromotion } from '../consts';
import styles from './developer-promotion-item.module.scss';
import texts from './developer-promotion-item.texts.json';
import React from 'react';
import Image from 'next/image';
import { pushDeveloperPromotionEvent } from '../../analytics/home-page-events';
import env from '@y2/environments';

type Props = {
  developerPromotion: DeveloperPromotion;
};

export const DeveloperPromotionItem = ({ developerPromotion }: Props) => {
  const { id, logo, name, text } = developerPromotion;
  return (
    <li
      className={styles.developerPromotionItemBox}
      onClick={() => pushDeveloperPromotionEvent(texts.forAllTheDetails)}
    >
      <Link href={`${env.routes.mainSite}/yad1/developers/${id}`}>
        <div className={styles.imageBox}>
          <Image alt={name} src={logo} height={82} width={176} />
        </div>
        <div className={styles.detailsBox}>
          <strong className={styles.name}>{name}</strong>
          <div className={styles.descriptionBox}>
            <p className={styles.description}>{text}</p>
          </div>
          <span className={styles.allDetails}>{texts.forAllTheDetails}</span>
        </div>
      </Link>
    </li>
  );
};
