import React from 'react';
import { BaseCarousel } from '../base-carousel/base-carousel';

type Props = {
  navigationPadding?: number;
  children?: React.ReactNode;
  spaceBetween?: number;
  mobileSpaceBetween?: number;
};

export const CommonCarousel = ({
  navigationPadding,
  spaceBetween = 20,
  mobileSpaceBetween = 24,
  children,
}: Props) => {
  return (
    <BaseCarousel
      navigationProps={{
        style: {
          paddingBottom: navigationPadding,
        },
      }}
      swiperProps={{
        slidesPerView: 'auto',
        spaceBetween: mobileSpaceBetween,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        breakpoints: {
          880: {
            spaceBetween,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
        },
      }}
    >
      {children}
    </BaseCarousel>
  );
};
