/* eslint-disable max-lines */
import styles from './new-properties.module.scss';
import texts from './new-properties.texts.json';
import React from 'react';
import { PropertyGroup } from './property-group/property-group';
import {
  DuplexMiniSpotIllustration,
  GardenMiniSpotIllustration,
  InvestMiniSpotIllustration,
  NewProjectMiniSpotIllustration,
  OfficeMiniSpotIllustration,
  SeaMiniSpotIllustration,
} from '@y2/mango/components/illustrations/mini-spot';
import { Wrapper } from '../../components/wrapper/wrapper';
import { Container } from '../../components/container/container';
import { PropertyItem } from './property-item/property-item';

export const NewProperties = () => {
  return (
    <Wrapper>
      <Container>
        <h2 className={styles.title}>{texts.title}</h2>
        <div className={styles.properties}>
          <PropertyGroup
            title={texts.newProjects}
            icon={<NewProjectMiniSpotIllustration />}
          >
            <PropertyItem
              text={texts.newProjectsInTelAviv}
              href="/newprojects?topArea=2&area=1&city=5000&propertyType=19"
            />
            <PropertyItem
              text={texts.newProjectsInRamatHashron}
              href="/newprojects?topArea=19&area=18&city=2650&propertyType=19"
            />
            <PropertyItem
              text={texts.newProjectsInNetanya}
              href="/newprojects?topArea=19&area=17&city=7400&propertyType=19"
            />
            <PropertyItem
              text={texts.newProjectsInBatYam}
              href="/newprojects?topArea=2&area=11&city=6200&propertyType=19"
            />
            <PropertyItem
              text={texts.toAllCities}
              href="/newprojects?propertyType=19"
              theme="blue"
            />
          </PropertyGroup>
          <PropertyGroup
            title={texts.duplexes}
            icon={<DuplexMiniSpotIllustration />}
          >
            <PropertyItem
              text={texts.duplexesInBatYam}
              href="/newprojects?topArea=2&area=11&city=6200&propertyType=25"
            />
            <PropertyItem
              text={texts.duplexesInRamatGan}
              href="/newprojects?topArea=2&area=3&city=8600&propertyType=25"
            />
            <PropertyItem
              text={texts.duplexesInGivatayim}
              href="/newprojects?topArea=2&area=3&city=6300&propertyType=25"
            />
            <PropertyItem
              text={texts.duplexesInHerzliya}
              href="/newprojects?topArea=19&area=18&city=6400&propertyType=25"
            />
            <PropertyItem
              text={texts.toAllCities}
              href="/newprojects?propertyType=25"
              theme="blue"
            />
          </PropertyGroup>
          <PropertyGroup
            title={texts.houseOnTheSea}
            icon={<SeaMiniSpotIllustration />}
          >
            <PropertyItem
              text={texts.housesOnTheSeaInTelAviv}
              href="/newprojects?topArea=2&area=1&city=5000&propertyType=23"
            />
            <PropertyItem
              text={texts.housesOnTheSeaInNetanya}
              href="/newprojects?topArea=19&area=17&city=7400&propertyType=23"
            />
            <PropertyItem
              text={texts.housesOnTheSeaInBatYam}
              href="/newprojects?topArea=2&area=11&city=6200&propertyType=23"
            />
            <PropertyItem
              text={texts.housesOnTheSeaInHerzliya}
              href="/newprojects?topArea=19&area=18&city=6400&propertyType=23"
            />
            <PropertyItem
              text={texts.toAllCities}
              href="/newprojects?propertyType=23"
              theme="blue"
            />
          </PropertyGroup>
          <PropertyGroup
            title={texts.realestateInvestments}
            icon={<InvestMiniSpotIllustration />}
          >
            <PropertyItem
              text={texts.apartmentsForInvestmentInBeerSheva}
              href="/newprojects?topArea=43&area=22&city=9000&propertyType=36"
            />
            <PropertyItem
              text={texts.apartmentsForInvestmentInHaifa}
              href="/newprojects?topArea=25&area=5&city=4000&propertyType=36"
            />
            <PropertyItem
              text={texts.apartmentsForInvestmentInJerusalem}
              href="/newprojects?topArea=100&area=7&city=3000&propertyType=36"
            />
            <PropertyItem
              text={texts.apartmentsForInvestmentInGivatayim}
              href="/newprojects?topArea=2&area=3&city=6300&propertyType=36"
            />
            <PropertyItem
              text={texts.toAllCities}
              href="/newprojects?propertyType=36"
              theme="blue"
            />
          </PropertyGroup>
          <PropertyGroup
            title={texts.gardenApartments}
            icon={<GardenMiniSpotIllustration />}
          >
            <PropertyItem
              text={texts.gardenApartmentsInRamatHashron}
              href="/newprojects?topArea=19&area=18&city=2650&propertyType=garden"
            />
            <PropertyItem
              text={texts.gardenApartmentsInRamatGan}
              href="/newprojects?topArea=2&area=3&city=8600&propertyType=garden"
            />
            <PropertyItem
              text={texts.gardenApartmentsInHerzliya}
              href="/newprojects?topArea=19&area=18&city=6400&propertyType=garden"
            />
            <PropertyItem
              text={texts.gardenApartmentsInNetanya}
              href="/newprojects?topArea=19&area=17&city=7400&propertyType=garden"
            />
            <PropertyItem
              text={texts.toAllCities}
              href="/newprojects?propertyType=garden"
              theme="blue"
            />
          </PropertyGroup>
          <PropertyGroup
            title={texts.OfficesForsale}
            icon={<OfficeMiniSpotIllustration />}
          >
            <PropertyItem
              text={texts.OfficesForsaleInTelAviv}
              href="/newprojects?topArea=2&area=1&city=5000&propertyType=office"
            />
            <PropertyItem
              text={texts.OfficesForsaleInHerzliya}
              href="/newprojects?topArea=19&area=18&city=6400&propertyType=office"
            />
            <PropertyItem
              text={texts.OfficesForsaleInRamatGan}
              href="/newprojects??topArea=2&area=3&city=8600&propertyType=office"
            />
            <PropertyItem
              text={texts.OfficesForsaleInNetanya}
              href="/newprojects?topArea=19&area=17&city=7400&propertyType=office"
            />
            <PropertyItem
              text={texts.toAllCities}
              href="/newprojects?propertyType=office"
              theme="blue"
            />
          </PropertyGroup>
        </div>
      </Container>
    </Wrapper>
  );
};
