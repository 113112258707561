import { analytics } from '@y2/google-tag-manager/v2/synchronized';
import { categories } from '@y2/categories';

const {
  realEstate: {
    subCategories: { yad1 },
    id,
  },
} = categories;

export const pushSearchSubmitEvent = (detail: 'map' | 'feed') => {
  analytics.pushEvent({
    EventName: 'search',
    EventDetail: detail,
    EventText: 'null',
    EventGroup: 'yad1_interactions',
    SearchTerm: 'null',
  });
};

export const pushDeveloperPromotionEvent = (buttonText: string) => {
  analytics.pushEvent({
    EventName: 'company_deals',
    EventDetail: 'null',
    EventText: buttonText,
    EventGroup: 'yad1_interactions',
  });
};

export const pushPageEvent = (userId?: string) =>
  analytics.pushPageEvent({
    PageType: 'lobby',
    Section: 'yad1',
    Category: yad1.id.toString(),
    SubCategory: id.toString(),
    ...(userId && { UserID: userId }),
  });
