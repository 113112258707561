import { Wrapper } from '../../components/wrapper/wrapper';
import styles from './developers-promotions.module.scss';
import texts from './developers-promotions.texts.json';
import React from 'react';
import { Container } from '../../components/container/container';
import type { DeveloperPromotion } from './consts';
import { DeveloperPromotionItem } from './developer-promotion-item/developer-promotion-item';
import { SwiperSlide } from 'swiper/react';
import { CommonCarousel } from '../../components/carousel';

type Props = {
  developerPromotions: DeveloperPromotion[];
};

export const DeveloperPromotions = ({ developerPromotions }: Props) => {
  return (
    <Wrapper className={styles.developerPromotionsWrapper}>
      <Container className={styles.developerPromotionsContainer}>
        <h2 className={styles.heading}>{texts.title}</h2>
        <CommonCarousel navigationPadding={184} mobileSpaceBetween={30}>
          {developerPromotions.map((developerPromotion) => (
            <SwiperSlide className={styles.slide} key={developerPromotion.id}>
              <DeveloperPromotionItem developerPromotion={developerPromotion} />
            </SwiperSlide>
          ))}
        </CommonCarousel>
      </Container>
    </Wrapper>
  );
};
