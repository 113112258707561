import React, { createContext, useContext, useState } from 'react';
import noop from 'lodash/noop';

export type HoveredItem =
  | {
      coords: { lon: number; lat: number };
    }
  | undefined;

type HoveredItemContextProps = {
  value: HoveredItem;
  setValue: React.Dispatch<React.SetStateAction<HoveredItem>>;
};

const initialValue: HoveredItemContextProps = {
  value: undefined,
  setValue: noop,
};

const HoveredItemContext = createContext<HoveredItemContextProps>(initialValue);

type HoveredItemProviderProps = {
  children: React.ReactNode;
};
/**
 * This provider is used to provide the current hovered item coords to the mapbox components
 */
export const HoveredItemProvider = ({ children }: HoveredItemProviderProps) => {
  const [value, setValue] = useState<HoveredItem>();
  return (
    <HoveredItemContext.Provider value={{ value, setValue }}>
      {children}
    </HoveredItemContext.Provider>
  );
};

export const useHoveredItemContext = () => {
  const hoveredItemContext = useContext(HoveredItemContext);
  if (!HoveredItemContext) {
    throw new Error(
      'useHoveredItemContext must be used within a useHoveredItemProvider scope.',
    );
  }

  return hoveredItemContext;
};
