import cn from 'classnames';
import {
  getImageSrc,
  getSkeletonImageUrl,
  getImageLoaderProps,
} from './utils/lambda-image.utils';
import styles from './lambda-image.module.scss';
import NextImage, { type ImageProps } from 'next/image';
import { useState, type SyntheticEvent, useCallback } from 'react';

type Props = Omit<ImageProps, 'src'> & {
  src: string;
  fallbackSrc?: string;
  originalResolution?: boolean;
  skeletonImageClassName?: string;
  hasBackgroundSkeleton?: boolean;
};

export const LambdaImage = ({
  fallbackSrc = '',
  originalResolution,
  skeletonImageClassName,
  hasBackgroundSkeleton = true,
  ...nextProps
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const { width, height, className, onError, onLoad, src } = nextProps;
  const imageSrc = getImageSrc(src, hasFailed, fallbackSrc, originalResolution);

  const handleError = useCallback(
    (event: SyntheticEvent<HTMLImageElement, Event>) => {
      setHasFailed(true);
      onError?.(event);
    },
    [onError],
  );

  const skeletonImageUrl = getSkeletonImageUrl(src);

  return (
    <div
      data-testid="image-box"
      className={styles.imageBox}
      style={{ height: height || '100%', width: width || '100%' }}
    >
      <div
        className={cn(
          styles.skeletonBox,
          hasBackgroundSkeleton && styles.backgroundSkeleton,
        )}
      >
        {skeletonImageUrl && (
          <div
            aria-hidden
            data-testid="image-skeleton"
            style={{ backgroundImage: `url(${skeletonImageUrl})` }}
            className={cn(
              skeletonImageClassName || styles.defaultSkeletonStyle,
              styles.skeletonImage,
            )}
          />
        )}
      </div>

      <NextImage
        {...getImageLoaderProps(imageSrc, originalResolution)}
        {...nextProps}
        src={imageSrc}
        data-testid="image"
        className={cn(
          className,
          styles.image,
          isLoaded ? styles.imageLoaded : styles.imageLoading,
        )}
        onLoad={(img) => {
          setIsLoaded(true);
          onLoad?.(img);
        }}
        onError={handleError}
      />
    </div>
  );
};
