/* eslint-disable id-length */
import {
  DEFAULT_CROP_STYLE,
  LAMBDA_MINIMIZED_IMAGE_SIZE,
  ORIGINAL_IMAGE_CROP,
} from '../lambda-image.consts';
import env from '@y2/environments';
import { ImageLoaderProps, type ImageProps } from 'next/image';

const isImageFromLambda = (src: string) => src?.includes(env.assets.imagesPath);

const isImageFromAssets = (src: string) => src?.includes(env.assets.basePath);

export const getImageSrc = (
  src: string,
  hasFailed: boolean,
  fallbackSrc: string,
  originalResolution?: boolean,
) => {
  if (hasFailed || !src) return fallbackSrc;

  if (originalResolution && isImageFromLambda(src)) {
    const srcUrl = new URL(src);
    srcUrl.searchParams.set('c', ORIGINAL_IMAGE_CROP);
    return srcUrl.toString();
  }

  return src;
};

export const getImageLoaderProps = (
  imageSrc: string,
  originalResolution?: boolean,
): Partial<ImageProps> => {
  if (isImageFromAssets(imageSrc)) return {};

  if (!isImageFromLambda(imageSrc) || originalResolution)
    return { unoptimized: true };

  return {
    loader: ({ src, width }: ImageLoaderProps) =>
      getImageUrl(src, width, width),
  };
};

const getImageUrl = (src: string, width: number, height: number) => {
  const imageUrl = new URL(src);
  imageUrl.search = getDimensionParams(width, height);

  return imageUrl.toString();
};

const getDimensionParams = (width: number, height: number) => {
  const imageParams = new URLSearchParams({
    w: width.toString(),
    h: height.toString(),
    c: DEFAULT_CROP_STYLE,
  });

  return imageParams.toString();
};

export const getSkeletonImageUrl = (src: ImageLoaderProps['src']) =>
  isImageFromLambda(src) &&
  getImageUrl(
    src,
    LAMBDA_MINIMIZED_IMAGE_SIZE.width,
    LAMBDA_MINIMIZED_IMAGE_SIZE.height,
  );
