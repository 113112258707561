import Link from 'next/link';
import styles from './property-item.module.scss';
import cn from 'classnames';
import React from 'react';

type Props = {
  href: string;
  text: string;
  theme?: 'blue';
};

export const PropertyItem = ({ href, text, theme }: Props) => {
  return (
    <li className={cn(styles.propertyItem, theme && styles[theme])}>
      <Link href={href} data-nagish="new-property-link">
        {text}
      </Link>
    </li>
  );
};
