import { ItemImage } from '../../../components/feed-item/item-image/item-image';
import { useHoveredItemContext } from '../../map/context/hovered-item-context';
import { ItemTags } from '../../../components/feed-item/item-tags/item-tags';
import { FeedItem } from '../../../components/feed-item/feed-item';
import type { Project } from '@y2/api-clients/yad1';
import { getTags } from '../get-tags.util';
import React from 'react';
import { pushLikePojectEvent } from '../../analytics/feed-events';
import { useProjectUrl } from '../../../hooks/use-project-url/use-project-url';
import texts from '../../../pages/index.text.json';

type Props = {
  project: Project;
  index?: number;
  placement?: string;
};

export const FeedProjectItem = ({ project, index, placement }: Props) => {
  const { setValue } = useHoveredItemContext();
  const { coords } = project;

  const projectUrl = useProjectUrl(project.slug, {
    project: project.id.toString(),
    ComponentType: placement || 'map_side_feed',
    ComponentHeader: texts.projectsAcrossTheCountry,
    Location: index?.toString() || '999',
  });

  return (
    <li
      onMouseEnter={() => setValue({ coords })}
      onMouseLeave={() => setValue(undefined)}
    >
      <FeedItem
        imageSlot={
          <ItemImage
            mainImage={project.mainImage}
            hoverImage={project.secondaryImage}
            alt={project.projectName}
            tagSlot={<ItemTags tags={getTags(project)} />}
          />
        }
        title={project.projectName}
        subTitle={project.address}
        projectLogo={project.projectLogo}
        bottomLabel={project.promotionalLabel}
        href={projectUrl}
        likeId={project.id.toString()}
        onLike={() => pushLikePojectEvent('feed', project.id)}
      />
    </li>
  );
};
