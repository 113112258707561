import { pushNewPropertiesEvent } from '../../analytics/feed-events';
import styles from './property-group.module.scss';
import React from 'react';

type Props = {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
};

export const PropertyGroup = ({ title, icon, children }: Props) => {
  return (
    <div className={styles.propertyGroupBox}>
      <div className={styles.propertyHeader}>
        {icon}
        <h3 className={styles.title}>{title}</h3>
      </div>
      <ul className={styles.list} onClick={() => pushNewPropertiesEvent(title)}>
        {children}
      </ul>
    </div>
  );
};
