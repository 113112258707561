export type DeveloperPromotion = {
  id: number;
  logo: string;
  name: string;
  text: string;
};
import env from '@y2/environments';

export const developeresPromotionsMock = [
  {
    id: 764,
    logo: 'https://pics.yad2.co.il/Pics/2022/05/20220509153404.jpg',
    name: 'קבוצת גבאי',
    text: 'עם 30 שנות ניסיון, אנו מובילים ביזמות, בנייה ושיווק פרויקטי נדל"ן',
  },
  {
    id: 109,
    logo: 'https://pics.yad2.co.il/Pics/2022/04/20220414165932.jpg',
    name: 'אזורים',
    text: 'חברת יזמות ובנייה מובילה בישראל עם 60 שנות ניסיון ומגוון פרויקטים',
  },
  {
    id: 194,
    logo: 'https://pics.yad2.co.il/Pics/2024/03/20240319172138.jpg',
    name: 'אאורה ישראל',
    text: 'החברה המובילה בענף המגורים בישראל, מתכננים ובונים חלומות',
  },
  {
    id: 9,
    logo: 'https://pics.yad2.co.il/Pics/2024/05/20240529124559.jpg',
    name: 'אפריקה ישראל מגורים',
    text: '90 שנות בנייה ישראלית, יוצרים שכונות וקהילות מגורים איכותיות',
  },
  {
    id: 179,
    logo: 'https://pics.yad2.co.il/Pics/2021/09/20210901150511.jpg',
    name: 'רוטשטיין נדלן',
    text: '60 שנות מצוינות בבניית מתחמי מגורים איכותיים ברחבי הארץ',
  },

  {
    id: 1214,
    logo: 'https://cdn.yad2.treedis.com/clients/1000061/logo.png',
    name: 'רמי שבירו',
    text: 'קבוצת שבירו המובילה בתחום הנדל"ן, יוזמת ובונה את הארץ מאז 1944',
  },
  {
    id: 606,
    logo: `${env.assets.basePath}/y2_frontend/apps/realestate/yad1-logos/יובלים.jpg`,
    name: 'קבוצת יובלים',
    text: 'מובילים בייזום, תכנון, פיתוח ויצירתיות בבנייה מעל 20 שנה',
  },
  {
    id: 185,
    logo: 'https://cdn.yad2.treedis.com/clients/1000087/logo.png',
    name: 'עמרם אברהם',
    text: 'קבוצת עמרם אברהם – מגורים / מניבים / התחדשות עירונית',
  },
  {
    id: 602,
    logo: 'https://cdn.yad2.treedis.com/clients/1000009/logo.png',
    name: 'י.ח דמרי',
    text: 'דמרי מהחזון ועד המפתח, בונים איכות ומציעים תנאים משתלמים',
  },
  {
    id: 12,
    logo: 'https://cdn.yad2.treedis.com/clients/1000021/logo.png',
    name: 'שיכון ובינוי נדלן',
    text: 'שיכון ובינוי נדל"ן – בונים את המדינה כבר 100 שנה',
  },
  {
    id: 368,
    logo: 'https://cdn.yad2.treedis.com/clients/1000083/logo.png',
    name: 'אפי קפיטל',
    text: 'אפי קפיטל היא יותר מחברת נדל”ן, היא מפעל חיים, היא ייעוד',
  },
  {
    id: 127,
    logo: 'https://cdn.yad2.treedis.com/clients/1000037/logo.png',
    name: 'תדהר',
    text: 'קבוצת הנדל"ן הפרטית המובילה בישראל עם ניסיון של למעלה מ-30 שנה',
  },
  {
    id: 954,
    logo: 'https://cdn.yad2.treedis.com/clients/1000035/logo.png',
    name: 'קן התור יזמות בניה והנדסה',
    text: 'בונים מצוינות עם חדשנות וגמישות, מתאימים את עצמנו לכל אתגר בדרך',
  },
  {
    id: 1604,
    logo: 'https://cdn.yad2.treedis.com/clients/1000048/logo.png',
    name: 'גשם החזקות',
    text: 'גשם היא חברת יזמות ובניה הפועלת בכ-29 ערים ברחבי ישראל',
  },
  {
    id: 7,
    logo: 'https://cdn.yad2.treedis.com/clients/1000075/logo.png',
    name: 'בוני התיכון',
    text: '40 שנה שאנחנו בונים את הפרויקטים למגורים הטובים בישראל',
  },
];
