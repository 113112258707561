import React from 'react';
import styles from './radio-selector.module.scss';

type Props = {
  value: string;
  label: string;
  name: string;
  isChecked?: boolean;
};

export const RadioSelector = ({
  value,
  label,
  isChecked = false,
  name,
}: Props) => {
  return (
    <label className={styles.radioSelectorBox}>
      <input
        className={styles.radioSelector}
        type="radio"
        name={name}
        value={value}
        defaultChecked={isChecked}
      />
      <span className={styles.radioLabel}>{label}</span>
    </label>
  );
};
