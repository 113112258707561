import { ValueOf } from './valueOf';

export const cropStyleMap = {
  scale: '1',
  stretch: '3',
  none: '6',
  scaleWidth: '9',
} as const;

export type CropName = keyof typeof cropStyleMap;
export type CropEnum = ValueOf<typeof cropStyleMap>;
