import styles from './developers.module.scss';
import texts from './developers.texts.json';
import React from 'react';
import { DeveloperItem } from './developer-item/developer-item';
import type { Yad1Developer } from '@y2/api-clients/yad1';
import { Container } from '../../components/container/container';
import { Wrapper } from '../../components/wrapper/wrapper';

type Props = {
  developers: Yad1Developer[];
};

export const Developers = ({ developers }: Props) => {
  return (
    <Wrapper>
      <Container>
        <h2 className={styles.heading}>{texts.title}</h2>
        <ul className={styles.developersList}>
          {developers.map((developer) => (
            <DeveloperItem developer={developer} key={developer.name} />
          ))}
        </ul>
      </Container>
    </Wrapper>
  );
};
