import { FeedProjectItem } from '../project-item/feed-project-item/feed-project-item';
import { Pagination } from '@y2/mango/components/pagination';
import { Container } from '../../components/container/container';
import { Wrapper } from '../../components/wrapper/wrapper';
import styles from './page-projects.module.scss';
import { Project } from '@y2/api-clients/yad1';
import React from 'react';
import { useRouter } from 'next/router';
import { NoSearchResults } from '../no-search-results/no-search-results';

const PROJECTS_PER_PAGE = 9;

type Props = {
  projects: Project[];
  total: number;
  heading: string;
  similarProjects?: Project[];
  placement?: string;
};

export const PageProjects = ({
  projects,
  total,
  heading,
  similarProjects = [],
  placement,
}: Props) => {
  const { query, pathname } = useRouter();
  return (
    <Wrapper>
      <Container>
        {projects?.length > 0 ? (
          <>
            <h2 className={styles.heading}>{heading}</h2>
            <ul className={styles.projects}>
              {projects.map((project, index) => (
                <FeedProjectItem
                  key={project.id}
                  project={project}
                  index={index + 1}
                  placement={placement}
                />
              ))}
            </ul>
            <Pagination
              currentPage={query.page ? parseInt(query.page as string) : 1}
              totalPages={Math.ceil(total / PROJECTS_PER_PAGE) || 1}
              path={pathname}
              query={query}
              variant="yad1"
            />
          </>
        ) : (
          <NoSearchResults similarProjects={similarProjects} />
        )}
      </Container>
    </Wrapper>
  );
};
